import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Markdown from 'markdown-to-jsx'; // Import Markdown for rendering
import DataViewSidebar from './DataViewSidebar';
import './DataView.css';

const DataView = () => {
    const [directories, setDirectories] = useState([]);
    const [selectedDirectory, setSelectedDirectory] = useState('');
    const [directoryData, setDirectoryData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState('text');
    const [showGraph, setShowGraph] = useState(false);
    const [graphPath, setGraphPath] = useState('');
    const [error, setError] = useState('');
    const [allFiles, setAllFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const filesPerPage = 100; // Limit to 100 files per page

    //const baseURL = 'http://localhost:3002';
    const baseURL = 'http://192.168.69.74:3002';
    //const baseURL = 'https://analytichat.ddns.net'; // Ensure that the domain is using HTTPS

    const fetchFiles = (page) => {
        const offset = page * filesPerPage;
        axios.get(`${baseURL}/api/all_files_sorted`, {
            params: { offset, limit: filesPerPage }
        })
        .then((response) => {
            setAllFiles(response.data);
        })
        .catch((error) => {
            console.error('Error fetching all files:', error);
        });
    };

    useEffect(() => {
        fetchFiles(currentPage);

        axios.get(`${baseURL}/api/directories`)
            .then((response) => {
                setDirectories(response.data);
            })
            .catch((error) => {
                console.error('Error fetching directories:', error);
            });
    }, [currentPage]);

    useEffect(() => {
        if (selectedDirectory) {
            axios.get(`${baseURL}/api/get_directory_data?directory=${selectedDirectory}`)
                .then((response) => {
                    setDirectoryData(response.data);
                })
                .catch((error) => {
                    console.error('Error fetching directory data:', error);
                    setError('Failed to load directory data. Please try again.');
                });
        } else {
            setDirectoryData([]);
        }
    }, [selectedDirectory]);

    const handleFileSelection = (file) => {
        setSelectedFile(file);
        setShowGraph(false);
        setGraphPath('');
    };

    const structureGraphData = (rawData) => {
        try {
            return JSON.parse(rawData);
        } catch (e) {
            console.error('Failed to parse graph data:', e);
            return [];
        }
    };

    const handleVisualize = async () => {
        if (selectedFile) {
            let graphData;
            if (selectedColumn === 'kn_graph' && selectedFile.kn_graph) {
                graphData = structureGraphData(selectedFile.kn_graph);
            } else if (selectedColumn === 'kn_graph_full' && selectedFile.kn_graph_full) {
                graphData = structureGraphData(selectedFile.kn_graph_full);
            } else {
                console.error('Selected column data not available for graph visualization');
                return;
            }

            try {
                const response = await axios.post(`${baseURL}/api/generate_graph`, {
                    graphData: graphData
                });
                setGraphPath(`${baseURL}${response.data.graphPath}`);
                setShowGraph(true);
            } catch (error) {
                console.error('Error generating graph:', error);
            }
        }
    };

    return (
        <div className="data-view-container" style={{ display: 'flex', flexDirection: 'row' }}>
            <DataViewSidebar
                directories={directories}
                selectedDirectory={selectedDirectory}
                setSelectedDirectory={setSelectedDirectory}
                directoryData={directoryData}
                allFiles={allFiles}
                handleFileSelection={handleFileSelection}
                error={error}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                filesPerPage={filesPerPage}
            />
            <div className="content" style={{ padding: '20px', flex: 1 }}>
                <h2>File Content</h2>
                {selectedFile ? (
                    <div>
                        <h4>{selectedFile.file_name}</h4>
                        <select value={selectedColumn} onChange={(e) => setSelectedColumn(e.target.value)}>
                            <option value="text">Text</option>
                            <option value="title_summary">Title Summary</option>
                            <option value="kn_graph">KN Graph</option>
                            <option value="key_concepts">Key Concepts</option>
                            <option value="kn_graph_full">KN Graph Full</option>
                        </select>
                        <button onClick={handleVisualize}>Visualize Graph</button>
                        {showGraph && (
                            <iframe
                                src={graphPath}
                                style={{ width: '100%', height: '600px', border: 'none' }}
                                title="Knowledge Graph"
                            />
                        )}
                        <div>
                          <Markdown>{selectedFile[selectedColumn] || 'None'}</Markdown> {/* Render markdown content */}
                        </div>
                    </div>
                ) : (
                    <p>Select a file to view its content.</p>
                )}
            </div>
        </div>
    );
};

export default DataView;
