import React from 'react';

const DataReviewSidebar = ({
    collections,
    selectedCollection,
    setSelectedCollection,
    collectionName,
    setCollectionName,
    collectionDescription,
    setCollectionDescription,
    knGraphOption,
    setKnGraphOption,
    directories,
    selectedDirectories,
    setSelectedDirectories,
    files,
    handleDirectorySelection
}) => {
    return (
        <div className="data-review-sidebar" style={{ overflowY: 'scroll', height: '100vh', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', background: '#f9f9f9', width: '300px' }}>
            <h2>Collection Selection</h2>
            <select
                value={selectedCollection}
                onChange={(e) => setSelectedCollection(e.target.value)}
            >
                <option value="">Select a Collection</option>
                {collections.map((collection, index) => (
                    <option key={index} value={collection}>
                        {collection}
                    </option>
                ))}
            </select>

            <h3>New Collection</h3>
            <input
                type="text"
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
                placeholder="Collection Name"
            />
            <textarea
                value={collectionDescription}
                onChange={(e) => setCollectionDescription(e.target.value)}
                placeholder="Collection Description"
            />

            <h3>Graph Option</h3>
            <select
                value={knGraphOption}
                onChange={(e) => setKnGraphOption(e.target.value)}
            >
                <option value="kn_graph">KN Graph</option>
                <option value="kn_graph_full">KN Graph Full</option>
            </select>

            <h3>Directory Selection</h3>
            <select multiple value={selectedDirectories} onChange={(e) => {
                const options = Array.from(e.target.options);
                const selectedOptions = options.filter(option => option.selected).map(option => option.value);
                setSelectedDirectories(selectedOptions);
                selectedOptions.forEach(option => handleDirectorySelection(option));
            }}>
                {directories.map((directory, index) => (
                    <option key={index} value={directory}>
                        {directory}
                    </option>
                ))}
            </select>

            <h3>Files</h3>
            <ul>
                {files.map((file, index) => (
                    <li key={index}>
                        {file.file_name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DataReviewSidebar;
