import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ChatHistorySidebar = ({ onSelectChat }) => {
    const [chatHistory, setChatHistory] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                //const response = await axios.get('http://localhost:3002/api/chat-history');
                const response = await axios.get('http://192.168.69.74:3002/api/chat-history');
                //const baseURL = 'https://analytichat.ddns.net/api/chat-history'; // Ensure that the domain is using HTTPS
                if (Array.isArray(response.data)) {
                    const formattedData = response.data.map(chat => ({
                        username: chat.username || 'Unknown User',
                        user_query: chat.user_query || 'No query',
                        chat_history: chat.chat_history || 'No response',
                        Date: chat.Date || new Date().toISOString()
                    }));
                    setChatHistory(formattedData);
                } else {
                    setError('Unexpected data format.');
                }
            } catch (error) {
                setError('Failed to fetch chat history. Please try again.');
            }
        };

        fetchChatHistory();
    }, []);

    const handleChatSelection = (chat) => {
        onSelectChat(chat);
    };

    return (
        <div className="chat-history-sidebar" style={{ overflowY: 'auto', height: 'calc(100vh - 60px)', boxSizing: 'border-box', paddingTop: '60px' }}>
            {error && <div className="error-message">{error}</div>}
            <div className="chat-history-list">
                {chatHistory.map((chat, index) => (
                    <div key={index} className="chat-history-item" onClick={() => handleChatSelection(chat)}>
                        <strong>{chat.username || 'Unknown User'}</strong>: {chat.user_query || 'No query'} <br />
                        <small>{chat.Date ? new Date(chat.Date).toLocaleString() : 'Unknown Date'}</small>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChatHistorySidebar;
