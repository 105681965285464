import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const handleLogin = async (event) => {
        event.preventDefault();
        console.log('Logging in with:', username);
        
        //const response = await fetch('http://192.168.69.74:3002/api/login', {  // Updated to use IP address
        const response = await fetch('https://192.168.69.74:3002/api/login', {  // Updated to use IP address
        //const baseURL = await fetch('https://analytichat.ddns.net/api/login', { // Ensure that the domain is using HTTPS
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
        });
        const data = await response.json();
        console.log('Data received:', data);

        if (data.success) {
            console.log('Login successful, redirecting...');
            localStorage.setItem('isLoggedIn', 'true');
            window.location.reload();
        } else {
            alert(data.message || 'Login failed!');
        }
    };

    return (
        <div>
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
                <div>
                    <label>Username: </label>
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </div>
                <div>
                    <label>Password: </label>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
