import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataReviewSidebar from './DataReviewSidebar';
import './DataReview.css'; // Import the new CSS file

const DataReview = () => {
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [collectionName, setCollectionName] = useState('');
    const [collectionDescription, setCollectionDescription] = useState('');
    const [knGraphOption, setKnGraphOption] = useState('kn_graph');
    const [directories, setDirectories] = useState([]);
    const [selectedDirectories, setSelectedDirectories] = useState([]);
    const [filesData, setFilesData] = useState([]);
    
    //const baseURL = 'http://localhost:3002';
    const baseURL = 'http://192.168.69.74:3002';
    //const baseURL = 'https://analytichat.ddns.net'; // Ensure that the domain is using HTTPS

    useEffect(() => {
        axios.get(`${baseURL}/api/names`)
            .then((response) => {
                setCollections(response.data);
            })
            .catch((error) => console.error('Error fetching collections:', error));

        axios.get(`${baseURL}/api/directories`)
            .then((response) => {
                setDirectories(response.data);
            })
            .catch((error) => console.error('Error fetching directories:', error));
    }, []);

    const handleCollectionChange = (name) => {
        setSelectedCollection(name);

        axios.get(`${baseURL}/api/files/${name}`)
            .then((response) => {
                console.log('Received file IDs:', response.data);

                if (!Array.isArray(response.data)) {
                    throw new Error('File IDs response is not an array');
                }

                return axios.post(`${baseURL}/api/query-datatable`, { selectedIds: response.data });
            })
            .then((response) => {
                console.log('Queried detailed file data:', response.data);

                if (Array.isArray(response.data)) {
                    setFilesData(response.data);
                } else {
                    console.error('Unexpected response format:', response.data);
                    throw new Error('Query did not return an array of data');
                }
            })
            .catch((error) => {
                console.error('Error fetching files or querying articles:', error);
            });
    };

    return (
        <div className="data-review-container">
            <DataReviewSidebar
                collections={collections}
                selectedCollection={selectedCollection}
                setSelectedCollection={handleCollectionChange}
                collectionName={collectionName}
                setCollectionName={setCollectionName}
                collectionDescription={collectionDescription}
                setCollectionDescription={setCollectionDescription}
                knGraphOption={knGraphOption}
                setKnGraphOption={setKnGraphOption}
                directories={directories}
                selectedDirectories={selectedDirectories}
                setSelectedDirectories={setSelectedDirectories}
                files={filesData}
                handleDirectorySelection={() => {}}
            />
            <div className="table-wrapper">
                <h2>Data Review</h2>
                <p>Here you can review and analyze data from the selected collections and files.</p>

                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Directory</th>
                            <th>File Name</th>
                            <th>Title Summary</th>
                            <th>Key Concepts</th>
                            <th>Topic Title</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filesData.map(file => (
                            <tr key={file.id}>
                                <td>{file.id}</td>
                                <td>{file.directory}</td>
                                <td>{file.file_name}</td>
                                <td>{file.title_summary}</td>
                                <td>{file.key_concepts}</td>
                                <td>{file.topic_title}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DataReview;
