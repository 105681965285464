import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './Header.css'; // Ensure you have appropriate styles

const Header = ({ onSettingsClick, settingsActive }) => {
    const history = useHistory();
    const location = useLocation();
    const [selectedPage, setSelectedPage] = useState(location.pathname || '/');

    const handleTabChange = (page) => {
        setSelectedPage(page);
        history.push(page);
    };

    const handleLogout = () => {
        // Clear login state
        localStorage.removeItem('isLoggedIn');
        // Redirect to login page and reload to reset state
        history.push('/');
        window.location.reload();
    };

    return (
        <header className="header">
            <h1>Pearl</h1>
            <div className="tab-container">
                <button className={`tab-button ${selectedPage === '/' ? 'selected-button' : ''}`} onClick={() => handleTabChange('/')}>Home</button>
                <button className={`tab-button ${selectedPage === '/dataview' ? 'selected-button' : ''}`} onClick={() => handleTabChange('/dataview')}>Data View</button>
                <button className={`tab-button ${selectedPage === '/datareview' ? 'selected-button' : ''}`} onClick={() => handleTabChange('/datareview')}>Data Review</button>
                <button className={`tab-button ${selectedPage === '/chat' ? 'selected-button' : ''}`} onClick={() => handleTabChange('/chat')}>Chat</button>
                <button className={`settings-button ${settingsActive ? 'selected-button' : ''}`} onClick={onSettingsClick}>Settings</button>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
            <img src="/pearl_logo.jpg" alt="Pearl Logo" className="header-logo" />
        </header>
    );
};

export default Header;
