import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SettingsModal.css'; // Reuse existing styles or create new ones for Chat Settings

const ChatSettingsModal = ({ isOpen, onClose, useRAG, setUseRAG, setSelectedIds }) => {  // Added setSelectedIds
  const [names, setNames] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [selectedIds, setInternalSelectedIds] = useState([]);

  //const baseURL = 'http://localhost:3002'; // Ensure baseURL is defined and used
  const baseURL = 'http://192.168.69.74:3002'; // Ensure baseURL is defined and used
  //const baseURL = 'https://analytichat.ddns.net'; // Ensure that the domain is using HTTPS
  

  // Fetch names from API on component mount
  useEffect(() => {
    async function fetchNames() {
      try {
        const response = await axios.get(`${baseURL}/api/names`);
        setNames(response.data);
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    }
    fetchNames();
  }, [baseURL]);

  // Handle name selection
  useEffect(() => {
    async function fetchFileIds() {
      if (selectedName) {
        try {
          const response = await axios.get(`${baseURL}/api/files/${selectedName}`);
          setInternalSelectedIds(response.data);
          setSelectedIds(response.data);  // Propagate selected IDs to parent state
        } catch (error) {
          console.error('Error fetching file IDs:', error);
        }
      }
    }
    fetchFileIds();
  }, [selectedName, baseURL, setSelectedIds]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Chat Settings</h2>
        <button className="close-button" onClick={onClose}>X</button>
        <div className="setting-item">
          <label>
            <input
              type="checkbox"
              checked={useRAG}
              onChange={e => setUseRAG(e.target.checked)}
            />
            Use RAG
          </label>
        </div>
        
        {/* Dropdown for selecting names */}
        <div className="setting-item">
          <label>
            Select a Name: 
            <select value={selectedName} onChange={(e) => setSelectedName(e.target.value)}>
              <option value="">--Select a Name--</option>
              {names.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </label>
        </div>

        {/* Display selected IDs */}
        {selectedIds.length > 0 && (
          <div className="setting-item">
            <h3>Selected File IDs:</h3>
            <ul>
              {selectedIds.map((id, index) => (
                <li key={index}>{id}</li>
              ))}
            </ul>
          </div>
        )}

      </div>
    </div>
  );
};

export default ChatSettingsModal;
