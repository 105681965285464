import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SettingsModal.css';

const SettingsModal = ({ isOpen, onClose, onSelectPrompt }) => {
    const [prompts, setPrompts] = useState([]);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [activeTab, setActiveTab] = useState('Main');
    const [showFullText, setShowFullText] = useState(false);
    const [documentRetrieval, setDocumentRetrieval] = useState(false); // State for Document Retrieval toggle

    useEffect(() => {
        if (isOpen && activeTab === 'Prompts') {
            axios.get('http://localhost:3002/api/prompts')
                .then(response => {
                    console.log('Raw Prompts Fetch Response:', response.data);
                    setPrompts(response.data);
                })
                .catch(error => console.error('Error fetching prompts:', error));
        }
    }, [isOpen, activeTab]);

    const handleSelect = (event) => {
        const promptId = event.target.value;
        const prompt = prompts.find(p => p.id.toString() === promptId);
        setSelectedPrompt(prompt || null);
        setShowFullText(false);
        console.log('Selected Prompt:', prompt);
    };

    const handleSave = () => {
        if (activeTab === 'Prompts' && selectedPrompt) {
            onSelectPrompt(selectedPrompt.prompt_text);
        }
        console.log('Document Retrieval is', documentRetrieval ? 'enabled' : 'disabled'); // Log the state of Document Retrieval
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Settings</h2>
                <div className="tabs">
                    {['Main', 'Prompts', 'Data Upload', 'Other Settings'].map((tab) => (
                        <button
                            key={tab}
                            className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </button>
                    ))}
                </div>
                <div className="tab-content">
                    {activeTab === 'Main' && (
                        <MainContent 
                            documentRetrieval={documentRetrieval} 
                            setDocumentRetrieval={setDocumentRetrieval}
                        />
                    )}
                    {activeTab === 'Prompts' && (
                        <div>
                            <h3>Select a System Prompt</h3>
                            <select onChange={handleSelect} value={selectedPrompt?.id || ''}>
                                <option value="">Select a prompt</option>
                                {prompts.map(prompt => (
                                    <option key={prompt.id} value={prompt.id}>
                                        {prompt.prompt_name}
                                    </option>
                                ))}
                            </select>
                            {selectedPrompt && (
                                <div>
                                    <h3>Selected Prompt Details</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Prompt Name</th>
                                                <th>Prompt Text</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{selectedPrompt.prompt_name}</td>
                                                <td>
                                                    <div style={{ maxWidth: '400px', display: 'flex', alignItems: 'center' }}>
                                                        <span>
                                                            {showFullText ? selectedPrompt.prompt_text : `${selectedPrompt.prompt_text.substring(0, 100)}...`}
                                                        </span>
                                                        {selectedPrompt.prompt_text.length > 100 && (
                                                            <button onClick={() => setShowFullText(!showFullText)}>
                                                                {showFullText ? 'Show Less' : 'Show More'}
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    )}
                    {activeTab === 'Data Upload' && <DataUploadContent />}
                    {activeTab === 'Other Settings' && <OtherSettingsContent />}
                </div>
                <button onClick={handleSave}>Save</button>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

const MainContent = ({ documentRetrieval, setDocumentRetrieval }) => (
    <div>
        <h3>Main Settings</h3>
        <p>Configure your main settings here.</p>
        <div>
            <label>
                Document Retrieval: 
                <input 
                    type="checkbox" 
                    checked={documentRetrieval} 
                    onChange={() => setDocumentRetrieval(!documentRetrieval)}
                />
            </label>
        </div>
    </div>
);

const DataUploadContent = () => (
    <div>
        <h3>Data Upload</h3>
        <p>Upload your data files here.</p>
    </div>
);

const OtherSettingsContent = () => (
    <div>
        <h3>Other Settings</h3>
        <p>Configure other application settings here.</p>
    </div>
);

export default SettingsModal;
