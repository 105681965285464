import React from 'react';
import './Home.css';  // Ensure you style the side panel and main layout

const Home = () => {
    return (
        <div className="home-container">
            <div className="text-panel">
                <h1>Welcome to Pearl</h1>
                <p>Your productivity and data companion.</p>
            </div>
            <div className="image-panel">
                <img src="/pearl_logo.jpg" alt="Pearl Logo" className="home-logo" />
            </div>
        </div>
    );
};

export default Home;
