import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import '../styles.css'; 
import ChatSettingsModal from './ChatSettingsModal';

const ChatWindow = ({ selectedChat, systemPrompt }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [initialLoad, setInitialLoad] = useState(true); 
    const [edited, setEdited] = useState(false);
    const [useRAG, setUseRAG] = useState(false); 
    const [isChatSettingsOpen, setIsChatSettingsOpen] = useState(false);

    useEffect(() => {
        if (selectedChat) {
            try {
                const loadedMessages = JSON.parse(selectedChat.chat_history);
                setMessages(loadedMessages);
                setInitialLoad(false); 
                setEdited(false); 
            } catch (err) {
                console.error('Error parsing chat history:', err);
                setMessages([]);
            }
        }
    }, [selectedChat]);

    const handleSend = async () => {
        if (!input.trim()) return;
        
        setEdited(true);
        const userMessage = { role: 'user', content: input };
        const systemMessage = { 
            role: 'system', 
            content: systemPrompt || '...'
        };
        const newMessages = [...messages, systemMessage, userMessage];

        setMessages(newMessages);
        setInput('');
        setError('');

        try {
            setLoading(true);
            const endpoint = useRAG ? 'http://localhost:3002/api/rag-chat' : 'http://localhost:3002/api/chat';
            const payload = useRAG ? { userInput: input } : { messages: newMessages };
            const response = await axios.post(endpoint, payload);
            const assistantMessage = useRAG 
                ? { role: 'assistant', content: response.data.content } 
                : { role: 'assistant', content: response.data.choices[0].message.content };
            setMessages([...newMessages, assistantMessage]);
        } catch (err) {
            console.error('Error sending message:', err);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleClear = async () => {
        const isNewOrUnchanged = initialLoad || (selectedChat && selectedChat.chat_history === JSON.stringify(messages));

        if (!edited && isNewOrUnchanged) {
            setMessages([]);
            return;
        }

        const promptName = selectedChat?.prompt_name || 'Default Prompt';

        try {
            const response = await axios.post('http://localhost:3002/api/clear', { messages, promptName });

            if (response.status === 200) {
                setMessages([]);
                setEdited(false);
            } else {
                setError('Failed to clear chat on the server. Please try again.');
            }
        } catch (err) {
            setError('Failed to clear chat. Please try again.');
        }
    };

    return (
        <div 
            className="chat-window" 
            style={{ 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100vh', 
                boxSizing: 'border-box', 
                paddingTop: '60px'
            }}
        >
            <div 
                className="chat-area" 
                style={{ 
                    flex: 1, 
                    overflowY: 'auto',
                    padding: '10px'
                }}
            >
                {error && <div className="error-message">{error}</div>}
                <div className="messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={msg.role === 'user' ? 'user' : 'bot'}>
                            <strong>{msg.role === 'user' ? 'User' : 'Bot'}:</strong> 
                            <Markdown>{msg.content}</Markdown>
                        </div>
                    ))}
                    {loading && <div>Loading...</div>}
                </div>
                <div 
                    className="chat-input-bar" 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        padding: '10px', 
                        borderTop: '1px solid #ccc',
                        backgroundColor: 'white',
                        position: 'sticky',
                        bottom: 0
                    }}
                >
                    <input
                        type="text"
                        style={{ flex: 1, marginRight: '10px' }}
                        value={input}
                        placeholder="Type your message..."
                        onChange={e => setInput(e.target.value)}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSend();
                            }
                        }}
                    />
                    <button onClick={handleSend}>Send</button>
                    <button onClick={handleClear} style={{ marginLeft: '5px' }}>Clear</button>
                    <button onClick={() => setIsChatSettingsOpen(true)} style={{ marginLeft: '5px' }}>Chat Settings</button>
                </div>
            </div>
            <ChatSettingsModal 
                isOpen={isChatSettingsOpen} 
                onClose={() => setIsChatSettingsOpen(false)}
                useRAG={useRAG}
                setUseRAG={setUseRAG}
            />
        </div>
    );
};

export default ChatWindow;
