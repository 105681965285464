import React from 'react';

const DataViewSidebar = ({ directories, selectedDirectory, setSelectedDirectory, directoryData, allFiles, handleFileSelection, error, currentPage, setCurrentPage, filesPerPage }) => (
    <div className="data-view-sidebar">
        <h2>Directory Selection</h2>
        <select value={selectedDirectory} onChange={(e) => setSelectedDirectory(e.target.value)}>
            <option value="">Select a Directory</option>
            {directories.map((directory, index) => (
                <option key={index} value={directory}>
                    {directory}
                </option>
            ))}
        </select>
        <h3>Files from Directory</h3>
        {error && <div className="error-message">{error}</div>}
        <ul>
            {directoryData.map((data, index) => (
                <li key={index} onClick={() => handleFileSelection(data)}>
                    {data.file_name}
                </li>
            ))}
        </ul>
        <h3>All Files</h3>
        <ul>
            {allFiles.map((file, index) => (
                <li key={index} onClick={() => handleFileSelection(file)}>
                    {file.file_name}
                </li>
            ))}
        </ul>
        <div className="pagination-controls">
            <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0}>
                Previous
            </button>
            <button onClick={() => setCurrentPage(prev => prev + 1)}>
                Next
            </button>
        </div>
    </div>
);

export default DataViewSidebar;
